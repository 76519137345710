/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import { computed, type MaybeRef } from 'vue'
import type Engagement from '~/src/Domain/Engagement/Engagement'
import type Phase from '~/src/Domain/Engagement/Phase'
import type Organisation from '~/src/Domain/Organisation/Organisation'
import WorkProgramNotStartedException from '~/src/Domain/WorkProgram/Exception/WorkProgramNotStartedException'
import FinalStep from '~/src/Domain/WorkProgram/Step/FinalStep'
import IntroStep from '~/src/Domain/WorkProgram/Step/IntroStep'
import type StepInterface from '~/src/Domain/WorkProgram/Step/StepInterface'
import useEngagementNavigation from '~/src/UserInterface/Engagement/composables/useEngagementNavigation'
import type WorkProgramProjection from '~/src/UserInterface/WorkProgram/projection/WorkProgramProjection'

export default function useWorkProgramNavigation(
  organisation: MaybeRef<Organisation>,
  engagement: MaybeRef<Engagement>,
  phase: MaybeRef<Phase>,
) {
  const { getEngagementLocalePath, navigateTo, uuidEncoder } = useEngagementNavigation(organisation, engagement, phase)

  const workProgramOverviewLocalePath = computed(() =>
    getEngagementLocalePath('workprogram-Overview'),
  )

  const getWorkProgramStepLocalePath = (
    workProgram: WorkProgramProjection,
    step: StepInterface,
  ) => {
    if (step instanceof IntroStep && !workProgram.isStarted) {
      return getEngagementLocalePath({
        name: 'workprogram-Introduction',
        params: {
          workProgramSlug: workProgram.slug.toString(),
        },
      })
    }

    if (!workProgram.isStarted || workProgram.iri === undefined) {
      throw new WorkProgramNotStartedException(
        `Cannot navigate to step with slug "${step.slug.toString()}", because workProgram with slug "${workProgram.slug.toString()}" has not yet been started.`,
      )
    }

    const workProgramId = uuidEncoder.encode(workProgram.iri.toId())
    if (step instanceof IntroStep) {
      return getEngagementLocalePath({
        name: 'workprogram-Settings',
        params: {
          workProgramId,
          workProgramSlug: workProgram.slug.toString(),
        },
      })
    }

    if (step instanceof FinalStep) {
      return getEngagementLocalePath({
        name: 'workprogram-Finalise',
        params: {
          workProgramId,
          workProgramSlug: workProgram.slug.toString(),
        },
      })
    }

    return getEngagementLocalePath({
      name: 'workprogram-Step',
      params: {
        workProgramId,
        workProgramSlug: workProgram.slug.toString(),
        stepSlug: step.slug.toString(),
      },
    })
  }

  const navigateToWorkProgramOverviewPath = async () => {
    await navigateTo(workProgramOverviewLocalePath.value)
  }

  return {
    navigateTo,
    getWorkProgramStepLocalePath,
    workProgramOverviewLocalePath,
    navigateToWorkProgramOverviewPath,
  }
}
